import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = {
  class: "modal",
  "data-backdrop": "static",
  "data-keyboard": "false"
}
const _hoisted_3 = { class: "modal-dialog" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "new-modal-header position-relative" }
const _hoisted_6 = { class: "modal-title text-center" }
const _hoisted_7 = { class: "display-flex" }
const _hoisted_8 = {
  key: 0,
  class: "body-content div-icon"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_Transition, { name: "modal" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.modalPopUp.Heading), 1),
                  _createElementVNode("a", {
                    href: "javascript:void(0)",
                    "aria-hidden": "true",
                    class: "cross-icon",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Cancel()))
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", {
                      class: "fa fa-times close-color",
                      "aria-hidden": "true"
                    }, null, -1)
                  ]))
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["modal-body", {'no-side-padding': (_ctx.isContentLeftAligned && _ctx.modalPopUp.Button2Text !== _ctx.deleteBtn)}])
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.modalPopUp.IsIconVisible)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("img", {
                            class: _normalizeClass([{'delete-modalpopup-img-size': _ctx.modalPopUp.Button2Text === _ctx.deleteBtn}, "img-icon"]),
                            src: _ctx.modalPopUp.IconURL,
                            alt: "Warning icon"
                          }, null, 10, _hoisted_9)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: _normalizeClass(["body-content", {'text-left': _ctx.isContentLeftAligned, 'delete-modalpopup-fontsize': _ctx.modalPopUp.Button2Text === _ctx.deleteBtn}]),
                      innerHTML: _ctx.modalPopUp.ModelDescription
                    }, null, 10, _hoisted_10)
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "margin-hr" }, null, -1))
                ], 2),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("button", {
                    class: "btn btn-secondary btn-size",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Cancel()))
                  }, _toDisplayString(_ctx.modalPopUp.Button1Text), 1),
                  (_ctx.ShowHideSaveButton())
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn btn-size btn-primary",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Save()))
                      }, _toDisplayString(_ctx.modalPopUp.Button2Text), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}