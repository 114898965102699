import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "descIcon"
}
const _hoisted_2 = {
  key: 1,
  class: "ascIcon"
}
const _hoisted_3 = {
  key: 0,
  class: "margin-top-20"
}
const _hoisted_4 = {
  key: 0,
  class: "cropAndScaleNotification"
}
const _hoisted_5 = { class: "display-flex" }
const _hoisted_6 = { class: "divCroppie" }
const _hoisted_7 = {
  key: 1,
  class: "cropAndScaleNotification"
}
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cropper = _resolveComponent("cropper")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "cropAndScale",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ShowDivImageScaleandCrop()))
    }, [
      _createTextVNode(_toDisplayString(_ctx.GetTitle()) + " ", 1),
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          (_ctx.showImageScaleandCrop)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
                _createElementVNode("i", {
                  class: "fa fa-sort-desc",
                  "aria-hidden": "true"
                }, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
                _createElementVNode("i", {
                  class: "fa fa-sort-asc",
                  "aria-hidden": "true"
                }, null, -1)
              ])))
        ])
      ])
    ]),
    (_ctx.showImageScaleandCrop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.localModuleType === _ctx.imageCroppie.Notification)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('Notification.ImageProcess.AdjudtthumbnailDescription')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_cropper, {
                ref: "imgcropper",
                class: "cropper",
                src: _ctx.GetImgeUrl(),
                "check-cross-origin": false,
                "check-orientation": false,
                "default-position": _ctx.defaultPosition,
                "default-size": _ctx.defaultSize,
                "resize-image": false,
                "background-class": "cropper",
                "foreground-class": "cropper",
                canvas: false,
                "stencil-props": _ctx.localIsFullImage ? {} : _ctx.stencilprops,
                onChange: _ctx.change
              }, null, 8, ["src", "default-position", "default-size", "stencil-props", "onChange"])
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
          ]),
          (_ctx.localModuleType === _ctx.imageCroppie.Notification)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", {
                  innerHTML: _ctx.$t('Notification.ImageProcess.AdjudtthumbnailNote')
                }, null, 8, _hoisted_8)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}